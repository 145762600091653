import { HostListener } from '@angular/core';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as $ from 'jquery';

@Component({
    selector: 'my-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent {
    someOtherData = [
        // {
        //   id: 1,
        //   template: '<div  *ngIf="data.message"> {{data.class}}',
        // },
        // {
        //   id: 2,
        //   template: '<div>{{data.timestampStr}}</div>',
        // },
        // {
        //   id: 3,
        //   template: '<div>{{data.message}}</div>',
        // },
    ];

    someData = [];

    reportData:any = []
    reportPrintData :any= []

    isloading: any = false;


    summaryBO: any = {};
    headerBO: any = {};
    printBO: any = {};
    printColl: any = [];
    printdetailsColl: any = [];
    additionalColl: any = []


    dataBO:any=null

    headerhtml: any = ""

    version: any = "2.2.0"


    //rpturl = "https://pivot.reemerp.com";

    constructor(private sanitized: DomSanitizer) { }

    ngOnInit(): void {
        // this.fnTestdata();
        console.log("Print App Running.. Local")

        var sdata = {
            'MESSAGE': "PAGELOADED",
            "version": this.version
        }
        window.parent.postMessage(sdata, "*");


        window.onmessage = (event) => {
            if (event.data["MESSAGE"]) {
                console.log(event)
                if (event.data["MESSAGE"] === 'PRINTDATA') {

                    var printtype = event.data["PRINTTYPE"]

                    console.log('child received successfully.')
                    console.log(event.data["SDATA"])

                    var sdata = event.data["SDATA"] as any

                    if (sdata.headerBO) {
                        this.headerBO = sdata.headerBO,
                            this.printBO = sdata.printBO,
                            this.printColl = sdata.printColl,
                            this.printdetailsColl = sdata.printdetailsColl
                        this.summaryBO = sdata.summaryBO
                        this.additionalColl = sdata.additionalColl
                    }

                    var data = {
                        headerBO: this.headerBO,
                        data: this.headerBO,
                        printBO: this.printBO,
                        printColl: this.printColl,
                        printdetailsColl: this.printdetailsColl,
                        summaryBO: this.summaryBO,
                        printtype: printtype,
                        additionalColl: this.additionalColl
                    }

                    this.someData.push(data)


                    this.headerhtml = this.sanitized.bypassSecurityTrustHtml(this.headerBO.htmlheadertxt)
                    htmlcss

                    var htmlcss = ""
                    htmlcss = this.headerBO.htmlcss;
                    if (!htmlcss) {
                        htmlcss = ``
                    }

                    var pagesize = 1180

                    if (this.headerBO.pagesize) {
                        pagesize = this.headerBO.pagesize;

                        if (!pagesize) {
                            pagesize = 1180
                        }

                    }

                    console.log("HTML CSS")
                    console.log(htmlcss)



                    var pagetype = ""
                    if (this.headerBO.pagetype) {
                        pagetype = this.headerBO.pagetype;
                    }


                    this.someOtherData.push({ id: 4, template: this.headerBO.htmlheadertxt, templatecss: [htmlcss], pagesize: pagesize, pagetype: pagetype })

                    //   `.header { color: red; } .header { color: green; } `

                    this.isloading = true



                }
                else if (event.data["MESSAGE"] === 'SENDPRINT') {
                    window.print();

                    //setTimeout(()=>{ window.print()},1000)
                }
                else if (event.data["MESSAGE"] === 'REPORTPRINTDATA') {

                    var printtype = event.data["PRINTTYPE"]

                    console.log('report child received successfully.')
                    console.log(event.data["SDATA"])

                    var sdata = event.data["SDATA"] as any

                    if (sdata.dataBO) {
                        this.dataBO = sdata.dataBO                         
                    }

                    var reportdata = {
                        dataBO: this.dataBO,                        
                        printtype:sdata.pagetype,                        
                    }
                    
                    this.reportData.push(reportdata)


                   // this.headerhtml = this.sanitized.bypassSecurityTrustHtml(sdata.htmlheadertxt)
                  
                   this.headerhtml =sdata.htmlheadertxt

                    var htmlcss = ""
                    htmlcss = sdata.htmlcss;
                    // if (!htmlcss) {
                    //     htmlcss = ``
                    // }

                    var pagesize = 1180

                    if (this.dataBO.pagesize) {
                        pagesize = this.dataBO.pagesize;

                        if (!pagesize) {
                            pagesize = 1180
                        }

                    }

                    console.log("HTML")
                    //console.log(this.headerhtml)
                    console.log("HTML CSS")
                    console.log(htmlcss)



                    var pagetype :string= sdata.pagetype
                    


                    this.reportPrintData.push({ id: 4, template: this.headerhtml, templatecss: [htmlcss], pagesize: pagesize, pagetype: pagetype })

                  
                    console.log(this.reportPrintData)
                    this.isloading = true



                }
                else if (event.data["MESSAGE"] === "GETHTML") {
                    console.log( document.documentElement.outerHTML);
                    var kdata = {
                        'MESSAGE': "RECEIVEHTML",
                        "htmltxt": document.documentElement.outerHTML
                    }
                    window.parent.postMessage(kdata, "*");


                }
            }
        }

    }


    fnTestdata() {
        this.headerBO.headername = 'JINNA'
        this.headerBO.branchname = 'JINNA'


        this.headerBO = {
            accountid
                :
                null,
            address
                :
                "Building No. 34627, Al-Mustagbal Street,\nIBN Sina, P.O. Box 79452, Al-Khobar,\nKingdom of Saudi Arabia.",
            addressotherlan
                :
                "مبنى رقم 34627 شارع المستقبل\nابن سينا ، ص. ب 79452 ، الخبر ،\nالمملكة العربية السعودية.",
            bankdetails
                :
                "Axis Bank\nAccount No : 51448449655965\nBranch         : Ilanji\nIFC CODE    : IBC154848\n",
            bankheader
                :
                "BANK DETAILS",
            branchcode
                :
                "10",
            branchid
                :
                38,
            branchlogo
                :
                "https://reemerp.com/api/images/25_11_46_47_xe0cc8gljb1es84.png",
            branchname
                :
                "Reno  Company",
            branchnameotherlan
                :
                "ظلال عالمية شركة تجارية",
            branchsignature
                :
                null,
            companyid
                :
                5,
            companylicensename
                :
                "Reno  Company",
            country
                :
                "India",
            createdby
                :
                null,
            createdon
                :
                null,
            currenysymbol
                :
                "SAR ",
            email
                :
                null,
            enablearabicprint
                :
                'No',

            inactive
                :
                0,
            invoicename
                :
                "",
            licensename
                :
                null,
            logopath
                :
                "https://reemerp.com/api/images/25_11_46_47_xe0cc8gljb1es84.png",
            modifiedby
                :
                11,
            modifiedon
                :
                "2023-06-25 02:16:50",
            phone
                :
                "971552659549",
            poslastsyncon
                :
                null,
            printtemplateid
                :
                2,
            state
                :
                "Tamil Nadu",
            synccode
                :
                null,
            taxno
                :
                "311041971100003",
            taxregnum
                :
                "311041971100003",
            termsandcondition
                :
                "BIGmart is one of the largest hypermarkets and supermarkets \nchain in the UAE. The brand was launched in the region in 2007\n as a family business,",
            termsconditionheader
                :
                "Terms and Conditions",
            website
                :
                "dfdf"
        }


        this.someData.push(this.headerBO)
        this.headerhtml = this.sanitized.bypassSecurityTrustHtml(this.fnGetHt())

        this.someOtherData.push({ id: 4, template: this.fnGetHt() })
    }


    @HostListener('window:message', ['$event'])
    onMessage(event) {
        console.log(event);
    }



    fnGetHt() {
        return `
    <div class="page-copy-container-wrapper" id="dvprint">
    <div class="page-wrapper-table original_for_recipient">
        <div class="page-wrapper-tr" style="page-break-inside:avoid; page-break-after:auto;">
            <div>
                <div class="Active page-wrapper">
                    <div class="page-header">

                        <table cellspacing="0" cellpadding="0" class="branding" width="100%">
                            <colgroup>
                                <col style="width: 35%">
                                <col>
                                <col>
                                <col style="width: 35%">
                            </colgroup>
                            <tbody>
                                <!-- <tr>
                                    <td colspan="4">
                                        <table cellspacing="0" cellpadding="0" width="100%">
                                            <tbody>

                                                <tr>
                                                    <td>
                                                        <div class="logo center"><img _ngcontent-dhc-c311=""
                                                                src="https://reemerp.com/api/images/25_11_46_47_xe0cc8gljb1es84.png">
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr> -->
                                <tr>
                                    <td style="position:relative;vertical-align: top;">
                                        <table cellspacing="0" cellpadding="0" width="100%">
                                            <tbody>

                                                <tr>

                                                    <td class="org_orgname_logo" data-editor=".org_orgname_logo">
                                                        <div class="f18  uppdercase"> RENO COMPANY </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td class="org_address_logo" data-editor=".org_address_logo">
                                                        <div class="f14  uppdercase prewrap"> BUILDING NO. 34627,
                                                            AL-MUSTAGBAL STREET,
                                                            IBN SINA, P.O. BOX 79452, AL-KHOBAR,
                                                            KINGDOM OF SAUDI ARABIA.</div>
                                                        <div class="f12"> Phone No : 971552659549<br>
                                                            VAT No : 311041971100003</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td style="text-align:right;vertical-align:bottom;float:right;">
                                        <table cellspacing="0" cellpadding="0">
                                            <tbody>
                                                <tr>
                                                    <td class="contact_details" data-editor=".contact_details">

                                                        <div>

                                                            <div class="text-right f18 b uppdercase"> ظلال عالمية
                                                                شركة تجارية</div>
                                                            <div class="text-right f14 b uppdercase prewrap"> مبنى
                                                                رقم 34627 شارع المستقبل
                                                                ابن سينا ، ص. ب 79452 ، الخبر ،
                                                                المملكة العربية السعودية.</div>
                                                            <div class="text-right f12">
                                                            </div>
                                                            <div class="text-right f12">
                                                                971552659549 :متحرك </div>
                                                            <div class="text-right f12">
                                                                311041971100003 : الرقم الضريبي </div>
                                                        </div>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br>

                        <div class="header" style="border-top: #000 double;">
                            <div class="formname "> Tax Invoice / فاتورة
                                ضريبية </div>
                        </div>

                        <table cellspacing="0" cellpadding="0" class="invoice">
                            <tbody>
                                <tr>
                                    <td class="main-border" style="width: 100%;border-bottom    :none;">

                                        <table>
                                            <colgroup>
                                                <col style="width: 50%">
                                                <col style="width: 50%">
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style="border-bottom:1px solid #000;padding: 5px; border-right: 1px solid #000;vertical-align: top;">
                                                        <table cellspacing="0" class="customerdata"
                                                            data-editor=".customerdata td:not(.customerdata_label):not(.customerdata_item_label):not(.special)"
                                                            style="table-layout: fixed; ">
                                                            <colgroup>
                                                                <col style="width: 33%">
                                                                <col style="width: 33%">
                                                                <col style="width: 33%">
                                                            </colgroup>
                                                            <tbody>
                                                                <tr>
                                                                    <td colspan="2"
                                                                        data-editor=".customerdata td.customerdata_label">
                                                                        <div class="f16"> Haji HMR</div>
                                                                        <div class="f12"> SF NO: 637, 1A1, Thudiyalur
                                                                            Rd,
                                                                            near ANANDHA FABRICATION,
                                                                            Saravanampatti, Coimbatore</div>


                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        Mobile
                                                                    </td>
                                                                    <td> </td>
                                                                    <td class="txt-right"> :متحرك
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td class="customerdata_item_label">
                                                                        VAT No :

                                                                    </td>
                                                                    <td style="">3115454842</td>
                                                                    <td class="txt-right">:الرقم الضريبي

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    <td style="border-bottom:1px solid #000;vertical-align:top; ">
                                                        <table cellspacing="0" class="invoicedata"
                                                            data-editor=".invoicedata td:not(.invoicedata_item_label):not(.special)">
                                                            <colgroup>
                                                                <col style="width: 50%">
                                                                <col style="width: 50%">

                                                            </colgroup>
                                                            <tbody>
                                                                <tr>
                                                                    <td
                                                                        style="border:1px solid #000; vertical-align:top;">
                                                                        <table>
                                                                            <colgroup>
                                                                                <col style="width: 50%;">
                                                                                <col style="width: 50%;">
                                                                            </colgroup>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>INVOICE NO
                                                                                    </td>
                                                                                    <td class="txt-right"> sdfsdf</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colspan="2"
                                                                                        style="text-align: center;"> 4
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                    </td>
                                                                    <td
                                                                        style="border:1px solid #000; vertical-align:top;">
                                                                        <table>
                                                                            <colgroup>
                                                                                <col style="width: 50%;">
                                                                                <col style="width: 50%;">
                                                                            </colgroup>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>INVOICE NO
                                                                                    </td>
                                                                                    <td class="txt-right"> sdfsdf</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colspan="2"
                                                                                        style="text-align: center;"> 4
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style="border:1px solid #000; vertical-align:top;">
                                                                        <table>
                                                                            <colgroup>
                                                                                <col style="width: 50%;">
                                                                                <col style="width: 50%;">
                                                                            </colgroup>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>INVOICE NO
                                                                                    </td>
                                                                                    <td class="txt-right"> sdfsdf</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colspan="2"
                                                                                        style="text-align: center;"> 4
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                    </td>
                                                                    <td
                                                                        style="border:1px solid #000; vertical-align:top;">
                                                                        <table>
                                                                            <colgroup>
                                                                                <col style="width: 50%;">
                                                                                <col style="width: 50%;">
                                                                            </colgroup>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>INVOICE NO
                                                                                    </td>
                                                                                    <td class="txt-right"> sdfsdf</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colspan="2"
                                                                                        style="text-align: center;"> 4
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td
                                                                        style="border:1px solid #000; vertical-align:top;">
                                                                        <table>
                                                                            <colgroup>
                                                                                <col style="width: 50%;">
                                                                                <col style="width: 50%;">
                                                                            </colgroup>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>INVOICE NO
                                                                                    </td>
                                                                                    <td class="txt-right"> sdfsdf</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colspan="2"
                                                                                        style="text-align: center;"> 4
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                    </td>
                                                                    <td
                                                                        style="border:1px solid #000; vertical-align:top;">
                                                                        <table>
                                                                            <colgroup>
                                                                                <col style="width: 50%;">
                                                                                <col style="width: 50%;">
                                                                            </colgroup>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>INVOICE NO
                                                                                    </td>
                                                                                    <td class="txt-right"> sdfsdf</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colspan="2"
                                                                                        style="text-align: center;"> 4
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td
                                                                        style="border:1px solid #000; vertical-align:top;">
                                                                        <table>
                                                                            <colgroup>
                                                                                <col style="width: 50%;">
                                                                                <col style="width: 50%;">
                                                                            </colgroup>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>INVOICE NO
                                                                                    </td>
                                                                                    <td class="txt-right"> sdfsdf</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colspan="2"
                                                                                        style="text-align: center;"> 4
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>

                                                                    </td>
                                                                    <td
                                                                        style="border:1px solid #000; vertical-align:top;">
                                                                        <table>
                                                                            <colgroup>
                                                                                <col style="width: 50%;">
                                                                                <col style="width: 50%;">
                                                                            </colgroup>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>INVOICE NO
                                                                                    </td>
                                                                                    <td class="txt-right"> sdfsdf</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td colspan="2"
                                                                                        style="text-align: center;"> 4
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>




                                                            </tbody>
                                                        </table>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                        <br>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table class="billdetailsthead" cellspacing="0" border="0" cellpadding="0"
                            style="font-size: 14px;" data-editor=".billdetailsthead td">
                            <colgroup>
                                <col style="width:30%">
                                <col style="width:5%">
                                <col style="width:5%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td class="valign-mid"> Description</td>
                                    <td class="valign-mid">
                                        Qty
                                    </td>
                                    <td class="valign-mid ">Unit</td>
                                    <td class="valign-mid text-right">U.Price
                                    </td>
                                    <td class="valign-mid text-right">Gross Total
                                    </td>
                                    <td class="valign-mid text-right">Discount</td>
                                    <td class="valign-mid text-right">
                                        VAT
                                    </td>
                                    <td class="valign-mid text-right">Total </td>
                                </tr>
                                <tr>

                                </tr>
                            </tbody>
                        </table>

                    </div>
                    <!-- -->
                    <div class="page-content" style="height: 388px;">
                        <table class="tableboxLinetable" cellspacing="0" cellpadding="0" border="0">

                            <colgroup>
                                <col style="width:30%">
                                <col style="width:5%">
                                <col style="width:5%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                            </colgroup>
                            <tbody>
                                <tr >
                                    <td class="tableboxLine linebox1"
                                        style="width: 30%; left: 0px; border-left: 1px solid rgb(0, 112, 192); height: 388px;">
                                    </td>
                                    <td class="tableboxLine linebox2" style="width: 5%; height: 388px;">
                                    </td>
                                    <td class="tableboxLine linebox3" style="width: 5%; height: 388px;">
                                    </td>
                                    <td class="tableboxLine linebox4" style="width: 10%; height: 388px;">
                                    </td>
                                    <td class="tableboxLine linebox5" style="width: 10%; height: 388px;">
                                    </td>
                                    <td class="tableboxLine linebox12" style="width: 10%; height: 388px;">
                                    </td>
                                    <td style="width: 10%;" class="tableboxLine linebox5"
                                        style="width: 10%; height: 388px;">

                                    </td>
                                    <td style="width: 10%;" class="tableboxLine linebox5"
                                        style="width: 10%; height: 388px;"> </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="billdetailstbody" id="billdetailstbody" cellspacing="0" border="0" cellpadding="0"
                            data-editor=".billdetailstbody td">
                            <colgroup>
                                <col style="width:30%">
                                <col style="width:5%">
                                <col style="width:5%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                                <col style="width:10%">
                            </colgroup>
                            <tbody>
                                



                            </tbody>
                        </table>
                    </div>
                    <!-- HTML_page_footer -->
                    <div class="page-footer">
                        <table class="invoicedataFooter" cellspacing="0" style="width:100%;"
                            data-editor=".invoicedataFooter td">
                            <colgroup>
                                <col style="width: 100%">

                            </colgroup>
                            <tbody>
                                <tr style="background-color: #fff;">
                                    <td class="txt-bold txt-left" style="text-align:right;">Total : SAR : Eight Thousand
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <br>
                        <table cellspacing="0" cellpadding="0" class="invoice">
                            <tbody>
                                <tr>
                                    <td class="main-border">

                                        <table cellspacing="0" style="width: 100%;">
                                            <colgroup>
                                                <col style="width: 50%">
                                                <col style="width: 50%">
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style="border-top:none;vertical-align:top;position: relative;border-top:0px solid #000;border-bottom:1px solid #000; padding: 10px;">

                                                        <div class=" p10 " style="line-height: 18px;">
                                                            <div class="f14 b uppdercase"> BANK
                                                                DETAILS </div>
                                                            <div class="f14 "> Axis Bank<br>
                                                                Account No : 51448449655965v<br>
                                                                Branch : Ilanji<br>
                                                                IFC CODE : IBC154848<br>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td
                                                        style="border-top:none;vertical-align:top;position: relative;border-left:1px solid #000;border-bottom:1px solid #000; padding-bottom: 10px;">
                                                        <table cellspacing="0" style="width: 100%;"
                                                            class="invoiceTotal">
                                                            <colgroup>
                                                                <col style="width: 33%">
                                                                <col style="width: 33%">
                                                                <col style="width: 33%">
                                                            </colgroup>
                                                            <tbody>
                                                                <tr>
                                                                    <td class="txt-bg"
                                                                        data-editor=".invoiceTotal td:not(.special)">
                                                                        <div class="f13 b"> Gross</div>
                                                                    </td>
                                                                    <td class="txt-bg txt-right special hideonlyformat"
                                                                        data-editor=".invoiceTotal td.special">
                                                                        <div class="f13"> إجمالي</div>
                                                                    </td>
                                                                    <td class="txt-bg txt-right special hideonlyformat"
                                                                        data-editor=".invoiceTotal td.special">
                                                                        <div class="f13">
                                                                            <div class="text-right f14 b"> 6,956.52
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="txt-bg"
                                                                        data-editor=".invoiceTotal td:not(.special)">
                                                                        <div class="f13 b"> Discount</div>
                                                                    </td>
                                                                    <td class="txt-bg txt-right special hideonlyformat"
                                                                        data-editor=".invoiceTotal td.special">
                                                                        <div class="f13"> إجمالي</div>
                                                                    </td>
                                                                    <td class="txt-bg txt-right special hideonlyformat"
                                                                        data-editor=".invoiceTotal td.special">
                                                                        <div class="f13">
                                                                            <div class="text-right f14 b"> 0.00
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="txt-bg"
                                                                        data-editor=".invoiceTotal td:not(.special)">
                                                                        <div class="f13 b"> Taxable</div>
                                                                    </td>
                                                                    <td class="txt-bg txt-right special hideonlyformat"
                                                                        data-editor=".invoiceTotal td.special">
                                                                        <div class="f13"> إجمالي</div>
                                                                    </td>
                                                                    <td class="txt-bg txt-right special hideonlyformat"
                                                                        data-editor=".invoiceTotal td.special">
                                                                        <div class="f13">
                                                                            <div class="text-right f14 b"> 6,956.52
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td class="txt-bg"
                                                                        data-editor=".invoiceTotal td:not(.special)">
                                                                        <div class="f14 b"> VAT</div>
                                                                    </td>
                                                                    <td class="txt-bg txt-right special hideonlyformat"
                                                                        data-editor=".invoiceTotal td.special">
                                                                        <div class="f14"> إجمالي</div>
                                                                    </td>
                                                                    <td class="txt-bg txt-right special hideonlyformat"
                                                                        data-editor=".invoiceTotal td.special">
                                                                        <div class="f14">
                                                                            <div class="text-right f14 b"> 6,956.52
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="txt-bg"
                                                                        data-editor=".invoiceTotal td:not(.special)">
                                                                        <div class="f13 b"> Round Off
                                                                        </div>
                                                                    </td>
                                                                    <td class="txt-bg txt-right special hideonlyformat"
                                                                        data-editor=".invoiceTotal td.special">
                                                                        <div class="f13"> إجمالي</div>
                                                                    </td>
                                                                    <td class="txt-bg txt-right special hideonlyformat"
                                                                        data-editor=".invoiceTotal td.special">
                                                                        <div class="f13">
                                                                            <div class="text-right f13 b"> SAR
                                                                                8,000.00</div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td class="txt-bg"
                                                                        data-editor=".invoiceTotal td:not(.special)">
                                                                        <div class="f13 b"> Grand Total
                                                                        </div>
                                                                    </td>
                                                                    <td class="txt-bg txt-right special hideonlyformat"
                                                                        data-editor=".invoiceTotal td.special">
                                                                        <div class="f13"> إجمالي</div>
                                                                    </td>
                                                                    <td class="txt-bg txt-right special hideonlyformat"
                                                                        data-editor=".invoiceTotal td.special">
                                                                        <div class="f13">
                                                                            <div class="text-right f13 b"> SAR
                                                                                8,000.00</div>
                                                                        </div>
                                                                    </td>
                                                                </tr>


                                                            </tbody>
                                                        </table>

                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <canvas height="100" width="100"
                                                            title="A custom title attribute"
                                                            style="height: 100px; width: 100px;"></canvas>
                                                    </td>
                                                    <td>
                                                        <table cellspacing="0" style="width: 100%;">
                                                            <colgroup>
                                                                <col style="width: 33%">
                                                                <col style="width: 33%">
                                                                <col style="width: 33%">
                                                            </colgroup>
                                                            <tbody>
                                                                <tr style="height: 45px;">
                                                                    <td colspan="3" class="f13"> Remarks :
                                                                    </td>
                                                                </tr>
                                                                <tr style="height: 45px;">
                                                                    <td class="f13">
                                                                        Salesman
                                                                    </td>
                                                                    <td class="f13">
                                                                        Received
                                                                    </td>
                                                                    <td class="f13">

                                                                        For Reno Company

                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table cellspacing="0" cellpadding="0" class="branding_table">
                            <colgroup>
                                <col style="width: 80%">
                                <col style="width: 20%">
                            </colgroup>
                            <tbody>
                                <tr class="branding_rt">
                                    <td style="padding: 5px 0 0;font-size: 12px;line-height: 15px;font-weight: normal;">
                                    </td>
                                    <td
                                        style="text-align:left;padding: 5px 0 0;font-weight: bold;color: #7A7A7A;font-size: 10px;line-height: 20px;text-transform: uppercase;vertical-align:top;">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
    </div>
`
    }
}
